import React from 'react';
import s from '/styles/SkeletonLoader.module.css'; // Puedes personalizar los estilos

const ConverterSkeleton = () => {
  return (
    <div className='relative mt-2 md:mt-4'>
      <div className='flex justify-between'>
        <div className={`flex mx-4 px-12 py-6 md:mx-9 rounded-t-lg relative ${s.skeleton_box}`} />
        <div className='pt-4 md:pt-0 w-full pr-4 '>
          <div className='flex justify-around'>
            <div className={` text-center ${s.skeleton_box} w-[100px] h-[20px]`} />
            <div className={` text-center ${s.skeleton_box} w-[100px] h-[20px]`} />
          </div>
          <div className='flex justify-around pt-2'>
            <div className={`${s.skeleton_box} w-[55px] h-[20px]`} />
            <div className={`${s.skeleton_box} w-[55px] h-[20px]`} />
          </div>
        </div>
      </div>
      <div className='rounded-lg relative filter drop-shadow-sm md:px-9 px-4 py-6'>
        <div className='mx-auto md:w-full'>
          <div className={`${s.skeleton_box} w-full h-[40px] `} />
          <div className={`${s.skeleton_box} mx-auto w-10 h-10 rounded-full my-4`} />
          {/* <div className={`mt-3 ${s.skeleton_box} w-full h-[40px] mb-4`} /> */}
        </div>
        <div className='mx-auto md:w-full'>
          <div className={`${s.skeleton_box} w-full h-[40px]`} />
        </div>
        <div className='flex justify-center mt-5 md:mt-6'>
          <div className={`${s.skeleton_box} h-[36px] w-[140px] rounded-full`} />
        </div>
      </div>
    </div>
  );
};

export default ConverterSkeleton;
